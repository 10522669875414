<template>
  <v-container>
    <v-card elevation="8" min-height="500">
      <v-card-title>
    Questo servizio è riservato agli afferenti al Dipartimento di Informatica "Giovanni degli Antoni"
      </v-card-title>
      <v-card-text>Sarai scollegato tra pochi secondi</v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// import { mapState, mapActions } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "usernotfound",
  data: () => ({
  }),
  created: function () {
    console.log('usernotfound');
    var component = this;
    setTimeout(function() { console.log('expired'); component.exit(); }, 6000)
  },
  computed: {
    // ...mapState(["userInitials", "userRole"]),
  },
  methods: {
    ...mapActions({
      exit: 'logout',
    }),
  },
};
</script>